import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import { tracker } from 'utils/analytics';

import { MobileOnly } from 'shared/components';
import SubcategoriesPills from 'components/subcategories-pills';
import { CategorySubcategoryPills } from 'components/category-subcategory-pills';
import { useMenuHeaderHeading } from 'components/menu-header/use-menu-header-heading';
import { Typography } from 'src/typography';

import Menu from 'components/menu';
import { MainDispensaryLayout } from 'components/layouts/main-dispensary-layout';
import useStores from 'shared/hooks/use-stores';
import { AmplitudeEvents, useAmplitude } from 'shared/hooks/use-amplitude';
import CategoryTilesGrid from 'components/category-tiles-grid';
import { BreadCrumbs } from 'src/components/bread-crumbs';
import useDispensaryCategories from 'src/dispensary/hooks/use-dispensary-categories';
import { useProductsForCategoryPage } from '../hooks/use-products-for-category-page';

export default function DispensaryCategoryPage() {
  const { categories } = useDispensaryCategories();
  const { dispensary } = useDispensary();
  const category = useDispensaryCategory();
  const heading = useMenuHeaderHeading({ category, page: 'category' });
  const flags = useFlags();
  const updatedFilterIconsEnabled = flags['growth.ecomm.filter-icon-update.rollout'] ?? false;

  const { products, loading, totalCount, totalPages, productsCarouselQueries } = useProductsForCategoryPage({
    perPage: 100,
  });

  const { UI } = useStores();
  const amplitude = useAmplitude();
  const router = useRouter();
  const inAllProductsView = UI.onSearchPage;
  const { categoriesSubcategories, subcategories } = useMenuFilterOptions();
  const hasSubcategories = subcategories?.length > 0;
  const categoryName = router.query?.category;
  const searchQuery = router.query?.search;

  useEffect(() => {
    if (!_.isEmpty(categoryName) && !_.isEmpty(dispensary?.id)) {
      amplitude.log(AmplitudeEvents.menu.viewCategory, {
        categoryName,
        dispensaryId: dispensary.id,
        dispensaryName: dispensary?.name,
        menuVariant: UI.analyticsMenuVariant,
      });
    }
    // rationale: exhaustive deps cause these events to be fired more than we want
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryName]);

  useEffect(() => {
    if (!loading && searchQuery && dispensary && products) {
      tracker.setContext({
        activeDispensary: dispensary,
      });

      tracker.searchResults({ query: searchQuery, hasResults: products.length > 0 });
    }
    // rationale: exhaustive deps cause these events to be fired more than we want
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, searchQuery]);

  if (!dispensary) {
    return null;
  }

  return (
    <>
      <MobileOnly>
        <BreadCrumbsContainer adjustForFilterPills={hasSubcategories}>
          <BreadCrumbs />
        </BreadCrumbsContainer>
        {updatedFilterIconsEnabled && (
          <CategoryHeadingContainer adjustForFilterPills={hasSubcategories}>
            <Typography.Heading size='medium' tag='h1' casing='capitalize'>
              {heading}
            </Typography.Heading>
          </CategoryHeadingContainer>
        )}
        <FilterPillsContainer updatedFilterIconsEnabled={updatedFilterIconsEnabled}>
          {inAllProductsView ? (
            <CategorySubcategoryPills categoriesSubcategories={categoriesSubcategories} />
          ) : (
            <SubcategoryPillsContainer marginTop={updatedFilterIconsEnabled ? '20px' : '40px'} />
          )}
        </FilterPillsContainer>
      </MobileOnly>
      <Menu
        totalCount={totalCount}
        totalPages={totalPages}
        products={products}
        loading={loading}
        dispensary={dispensary}
        page='category'
        productsCarouselQueries={productsCarouselQueries}
      />
      <CategoryTilesGrid p='35px 25px 70px' categories={categories} collapsible={false} />
    </>
  );
}

DispensaryCategoryPage.layout = MainDispensaryLayout;

const BreadCrumbsContainer = styled.div`
  padding-top: 25px;
  margin-bottom: ${({ adjustForFilterPills }) => (adjustForFilterPills ? `-16px` : '0')};
  padding-left: ${({ adjustForFilterPills }) => (adjustForFilterPills ? `3px` : '0')};
`;

const SubcategoryPillsContainer = styled(SubcategoriesPills)`
  margin-top: ${({ marginTop }) => marginTop};
`;

const FilterPillsContainer = styled.div`
  margin-bottom: ${({ updatedFilterIconsEnabled }) => (updatedFilterIconsEnabled ? '16px' : '25px')};
`;

const CategoryHeadingContainer = styled.div`
  padding: ${({ adjustForFilterPills }) => (adjustForFilterPills ? '32px' : '16px')} 0 0 25px;
`;
